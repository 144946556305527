<template>
	<div class="orders">
		<h1>{{title}}</h1>

		<template v-if="plans && plans.length">

			<v-row class="mt-4">
				<v-col cols="12" md="2">
					<v-select v-model="newPlanId"
							  :items="planChannels"
							  :readonly="id?true:false"
							  @change="onChangePlan"
							  label="Канал" outlined dense hide-details></v-select>
				</v-col>
				<v-col cols="12" md="3">
					<v-select v-model="form.agentId"
							  :items="agentItems"
							  :readonly="isSubmitted"
							  @change="onChangeAgent"
							  label="Контрагент" outlined dense hide-details/>
				</v-col>
				<v-col v-if="hasPlannedProducts" cols="12" md="2">
					<v-text-field v-if="isReadonly"
								  v-model="form.dateFormatted"
								  readonly
								  label="Дата поставки"
								  append-icon="mdi-calendar"
								  outlined dense hide-details/>
					<v-dialog v-else
							  ref="dateModal"
							  v-model="isDateModal"
							  width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
									v-model="form.dateFormatted"
									label="Дата поставки"
									outlined dense hide-details
									append-icon="mdi-calendar"
									v-bind="attrs"
									:readonly="isReadonly"
									@blur="form.date=parseDate(form.dateFormatted)"
									v-on="on"/>
						</template>
						<v-date-picker
								v-model="form.date"
								locale="ru-RU"
								:first-day-of-week="1"
								:min="minDate"
								scrollable>
							<v-spacer></v-spacer>
							<v-btn text
								   @click="isDateModal=false">Отмена
							</v-btn>
							<v-btn text
								   color="primary"
								   @click="form.dateFormatted=formatDate(form.date);isDateModal=false">Ок
							</v-btn>
						</v-date-picker>
					</v-dialog>
				</v-col>
				<template v-if="isSubmitted">
					<v-col cols="12" md="5" class="d-flex justify-end">
						<v-chip :color="statusColor" dark>{{status}}</v-chip>
					</v-col>
				</template>
				<template v-else-if="hasPlannedProducts">
					<v-col cols="12" md="3">
						<v-btn color="primary" block @click="save(false)">Сохранить черновик</v-btn>
					</v-col>
					<v-col cols="12" md="2">
						<v-btn block @click="isDialogSubmit=true" :disabled="!hasAmounts">Отправить</v-btn>
					</v-col>
				</template>
			</v-row>

			<order-content v-if="isSubmitted"
						   :products="plannedProducts"
						   :order-items="initialItems"
						   :tree="tree"
						   :name-prop="localizedNameProp"/>
			<order-tables v-else-if="hasPlannedProducts"
						  :products="plannedProducts"
						  :tree="plannedTree"
						  :initial-items="initialItems"
						  :plan-items="planItems"
						  :confirmed="isConfirmed"
						  :readonly="isSubmitted"
						  :name-prop="localizedNameProp"
						  class="mt-8 mt-md-0"
						  @change="hasChanges=true"/>
			<v-card v-else class="mt-8">
				<v-card-text>
					{{plan.NAME}} либо еще не утвержден, либо не сформирован, либо не содержит продуктов, доступных данному контрагенту.
				</v-card-text>
				<v-card-actions>
					<v-btn text @click="$router.push({name:'plans'})">Проверить план</v-btn>
				</v-card-actions>
			</v-card>

			<v-row v-if="!isSubmitted && hasPlannedProducts" class="mt-4">
				<v-col cols="12" md="3" class="order-last order-md-first">
					<v-btn @click="onDelete">Удалить</v-btn>
				</v-col>
				<v-col cols="12" md="3" offset-md="4">
					<v-btn color="primary" block @click="save(false)">Сохранить черновик</v-btn>
				</v-col>
				<v-col cols="12" md="2">
					<v-btn block @click="isDialogSubmit=true" :disabled="!hasAmounts">Отправить</v-btn>
				</v-col>
			</v-row>
		</template>
		<v-row v-else class="mt-4">
			<v-col cols="12" sm="6" md="4">
				<v-card>
					<v-card-text>
						Планы на период "{{ periodName }}" еще не сформированы. Пожалуйста, вначале
						создайте план.
					</v-card-text>
					<v-card-actions>
						<v-btn text @click="$router.push({name:'plans'})">Создать план</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog
				v-model="isDialogSubmit"
				width="500"
		>
			<v-card>
				<v-card-title>
					Отправляем заказ?
				</v-card-title>

				<v-card-text>
					Отправить заказ на утверждение? С этого момента внести изменения в него будет
					невозможно. Продолжить?
				</v-card-text>

				<v-divider/>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							color="primary"
							text
							@click="isDialogSubmit=false"
					>
						Нет
					</v-btn>
					<v-btn
							color="primary"
							text
							@click="submit"
					>
						Да
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
				v-model="isDialogSubmitConfirm"
				width="500"
		>
			<v-card>
				<v-card-title>
					Заказ на проверке
				</v-card-title>

				<v-card-text>
					Заказ помечен к отправке на проверку. Пожалуйста, ожидайте изменения статуса в разделе
					Заказы.
				</v-card-text>

				<v-divider/>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							color="primary"
							text
							@click="isDialogSubmitConfirm=false"
					>
						Отлично
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
				v-model="isDialogDeleting"
				width="500">
			<v-card>
				<v-card-title>
					Удалить заказ?
				</v-card-title>

				<v-divider/>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							color="primary"
							text
							@click="isDialogDeleting=false"
					>
						Нет
					</v-btn>
					<v-btn
							color="primary"
							text
							@click="del"
					>
						Да
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar
				v-model="isSnackSaved"
				color="green"
		>
			Черновик заказа сохранен!

			<template v-slot:action="{ attrs }">
				<v-btn
						text
						v-bind="attrs"
						@click="isSnackSaved = false"
				>
					Ок
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
    import OrderTables from "./elements/order-tables";
    import OrderContent from "./elements/order-content";
    import {date2DDMMYYYY, date2Mysql, ddmmyyyy2Date, mysql2Date} from "../utils/date";

    export default {

        name: "orders-edit",
        components: {OrderTables, OrderContent},
        props: {
            id: {
                type: String | Number
            }
        },
        data: (vm) => ({
            newPlanId: null,
            form: {
                planId: null,
                agentId: null,
                date: null,
                dateFormatted: "",
            },
            minDate: null,
            isDateModal: false,
            loading: false,
            error: null,
            isDialogChanges: false,
            isDialogSubmit: false,
            isDialogSubmitConfirm: false,
            isSnackSaved: false,
			isDialogDeleting: false
        }),
        watch: {
        	// fixme wtf?
            date(val) {
                this.form.dateFormatted = this.formatDate(this.form.date);
            },
        },
        computed: {
            hasChanges: {
                get() {
                    return this.$store.state.hasChanges;
                },
                set(value) {
                    return this.$store.state.hasChanges = value;
                }
            },
            user() {
                return this.$store.state.session?.user;
            },
            agent() {
                return this.user.agents.find(el=>el.ID===this.form.agentId);
            },
            isReadonly() {
                return this.isSubmitted;
            },
            title() {
                if (this.order) return "Заказ #" + this.order.ID
                    + (this.order.SUBMIT_DATE ? " от " + this.order.SUBMIT_DATE : "");
                else return "Создание заказа на " + this.periodName;
            },
            tree() {
                return this.$store.state.page?.OrderController?.tree;
            },
			localizedNameProp() {
				return this.channel?.LOCALIZED_NAME;
			},
            /**
             * Returns tree of categories with non-empty plan amounts.
             */
            plannedTree() {
                // todo filter subsections
                const hasProducts = section => {
                    let hasPlannedProducts = this.plannedProducts.some(p => p.IBLOCK_SECTION_ID === section.ID);
                    return hasPlannedProducts || section.children?.some(c => hasProducts(c));
                };
                return this.tree.filter(c => hasProducts(c));
            },
            products() {
                return this.$store.state.page?.OrderController?.products;
            },
            /**
             * Returns only allowed products and only with non-empty plan amounts.
             */
            plannedProducts() {
                return this.products
                    .filter(p => this.agent?.JSON_PRODUCTS?.some(xmlId => xmlId === p.XML_ID))
                    .filter(p => {
                        const planAmount = (+this.planItems?.find(el => el.XML_ID === p.XML_ID)?.confirmedAmount) || 0;
                        return planAmount > 0;
                    });
            },
            hasPlannedProducts() {
                return this.isPlanConfirmed && !!this.plannedProducts?.length;
            },
            plans() {
                return this.$store.state.page?.OrderController?.plans;
            },
            plan() {
                return this.plans?.find(el => el.ID === this.form.planId);
            },
			channel() {
				return this.channels.find(el => el.ID === this.plan.CHANNEL_ID);
			},
            order() {
                return this.$store.state.page?.OrderController?.order;
            },
            periods() {
                return this.$store.state.page?.OrderController?.periods;
            },
            planChannels() {
                // это планы (один на каждый канал), но имя берем из канала, для понятности
                return this.plans.map(el => {
                    const name = this.channels.find(c => c.ID === el.CHANNEL_ID).NAME;
                    return {
                        text: name,
                        value: el.ID,
                    }
                });
            },
            agentItems() {
                return this.user.agents?.map(el => ({
                    text: el.NAME,
                    value: el.ID
                }));
            },
            period() {
                return this.$store.state.page?.OrderController?.period;
            },
            periodName() {
                return this.period?.NAME;
            },
            channels() {
                return this.$store.state.page?.OrderController?.channels;
            },
            statuses() {
                return this.$store.state.page?.OrderController?.statuses;
            },
			isCancelled() {
				return !!this.order?.CANCEL_DATE;
			},
            isConfirmed() {
                return !!this.order?.CONFIRM_DATE;
            },
            isSubmitted() {
                return !!this.order?.SUBMIT_DATE;
            },
            isExported() {
                return !!this.order?.EXPORT_DATE;
            },
			isPlanConfirmed() {
            	return this.plan?.CONFIRM_DATE;
			},
            status() {
                if (this.isCancelled) return "Заказ отменен";
                if (this.isConfirmed) return "Заказ утвержден";
                if (this.isExported) return "Заказ на утверждении";
                if (this.isSubmitted) return "Скоро будет отправлен";
            },
            statusColor() {
                return this.statuses?.find(el => el.ID === this.order?.STATUS)?.COLOR;
            },
            initialItems() {
                return this.order?.ITEMS_JSON || [];
            },
            planItems() {
                return this.plan?.ITEMS_JSON || [];
            },
			hasAmounts() {
				return this.plannedProducts.some(el=>el.amount>0);
			},
            submitForm() {
                return {
                    id: this.order?.ID,
                    planId: this.form.planId,
                    agentId: this.form.agentId,
                    date: this.form.dateFormatted,

					// сохраняем только номенклатуру, разрешенные данному контрагенту
                    items: this.plannedProducts.map(item => {
                        return {
                            XML_ID: item.XML_ID,
                            amount: item.amount,
                        };
                    })
                };
            }
        },
        methods: {
            onChangePlan() {
                if (this.hasChanges) {
                    this.isDialogChanges = true;
                } else {
                    this.form.planId = this.newPlanId;
                }
            },
            onChangeAgent(item) {
                //this.params.agentId = item.value;
            },
			onDelete() {
				this.isDialogDeleting = true;
			},
            save(withSubmit = false) {
                this.loading = true;
                this.error = null;

                const params = this.submitForm || {};
                params.withSubmit = withSubmit;
                return this.$store.dispatch('post', {
                    action: 'OrderController',
                    params
                }).then((res) => {
                    //this.$router.push({name: "orders"});

                    if (this.$route.name !== "order"
                        && res.page?.OrderController?.order?.ID) {
                        this.$router.push({
                            name: "order",
                            params: {
                                id: res.page?.OrderController?.order?.ID,
                            }
                        });
                    }
					else {
						this.$store.state.page.OrderController.order = res.page?.OrderController?.order || this.order;
						if (withSubmit) {
							//this.$store.state.page?.OrderController?.order
							//Vue.set(this.plan, "SUBMIT_DATE", date2dmy(new Date()));
							//Vue.set(this.plan, "STATUS", "На утверждении");
							this.isDialogSubmitConfirm = true;
						} else this.isSnackSaved = true;
					}
                }).catch((error) => {
                    this.error = error ? error : 'Не получилось...';
                }).finally(() => {
                    this.loading = false;
                    this.isDialogSubmit = false;
                });
            },
			del() {
				//console.log("DELETE!!!");
				this.loading = true;
				this.error = null;
				return this.$store.dispatch('del', {
					action: 'OrderController',
					params: {
						id: this.order?.ID,
					}
				}).then((res) => {
					this.$router.push({name: "orders"});
				}).catch((error) => {
					this.error = error?.message;
				}).finally(() => {
					this.loading = false;
				});
			},
            formatDate(date) {
                if (!date) return null;
                //return date2DDMMYYYY(mysql2Date(date));

                const [year, month, day] = date.split('-');
                return `${day}.${month}.${year}`;
            },
            parseDate(date) {
                if (!date) return null;
                //return date2Mysql(ddmmyyyy2Date(date));
                const [day, month, year] = date.split('.');
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            },
            submit() {
                this.save(true);
            }
        },
        created() {
            // this is called every visit to route order-edit (not only once)

            const today = new Date();
            this.minDate = today.toISOString().substr(0, 10);

            if (this.order) {
                this.newPlanId = this.form.planId = this.order.PLAN_ID;
                this.form.dateFormatted = this.order.CONFIRMED_DELIVERY_DATE || this.order.DELIVERY_DATE;
                this.form.date = this.formatDate(this.form.dateFormatted);
                this.form.agentId = this.order.AGENT_ID;
            } else {
            	// todo forbid selecting plan
                this.newPlanId = this.form.planId = this.plans ? this.plans[0]?.ID : null;

                //console.log("Today: ", today);
                today.setDate(today.getDate() + 1);	// todo mind weekend days
                //console.log("Today + 1: ", today);
                //console.log("Today ISO: ", today.toISOString().substr(0, 10));
                this.form.date = today.toISOString().substr(0, 10);
                this.minDate = today.toISOString().substr(0, 10);
                this.form.dateFormatted = this.formatDate(this.form.date);
                this.form.agentId = this.user.agents ? this.user.agents[0]?.ID : null;
            }
        }
    }
</script>

<style lang="scss">
</style>