<template>
	<div class="order-content">
		<v-row>
			<v-col cols="12">
				<v-data-table :headers="headers"
							  :items="items"
							  :disable-sort="true"
							  :items-per-page="-1"
							  :item-class="itemClass"
							  hide-default-footer class="order-content__products">

					<template v-slot:item.NAME="{ item }">
						<div class="order-content__section">{{ item.fullSectionName }}</div>
						<!--{{ item.SORT }}. -->{{ item.localizedName }}
					</template>

				</v-data-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
    import Vue from 'vue';
    import ProductTree from "@/components/elements/product-tree";

    export default {
        name: "order-content",
        props: {
            products: {	// all available products (in the system)
                type: Array
            },
			orderItems: {
				type: Array
			},
            nameProp: {
                type: String
            },
			tree: {
				type: Array
			},
        },
        data: () => ({
			items: []
        }),
		watch: {
			nameProp: {
				immediate: true,
				handler() {
					this.setLocalizedNames();
				}
			},
			initialItems: {
				immediate: true,
				//deep: true,
				handler() {
					this.initItems();
				}
			},
			products: {
				immediate: true,
				//deep: true,
				handler() {
					this.initItems();
				}
			}
		},
        computed: {
			headers() {
				const headers = [
					//{text: '#', value: 'i', width: '5%'},
					//{text: 'Код', align: 'start', sortable: false, value: 'CODE', width: '15%'},
					{
						text: 'Код',
						align: 'start',
						sortable: false,
						value: 'CODE',
						width: '25%'
					},
					{
						text: 'Продукт',
						align: 'start',
						sortable: false,
						value: 'NAME',
						width: '55%'
					},
					{text: 'Заказано', align: 'end', value: 'amount', width: '10%'},
					{text: 'Утверждено', align: 'end', value: 'confirmedAmount', width: '10%'}
				];
				return headers;
			},
		},
        methods: {
			itemClass(item) {
				if (item.amount !== item.confirmedAmount) return "altered";
				if (this.isEmptyItem(item)) return "empty";
				if (item.amount > item.plan) return "over";
			},
			isEmptyItem(item) {
				return typeof item.rest === "number" && !item.actual && !item.amount;
			},
			localizedName(item) {
				return item[this.nameProp] || item.NAME;
			},
			setLocalizedNames() {
				this.products?.forEach(el => Vue.set(el, "localizedName", this.localizedName(el)));
			},
			plainSectionsOf(item) {
				const plainTree = this.plainTree();

				const parentsOf = (section, parents = []) => {
					parents.push(section.NAME);

					if ( !section.IBLOCK_SECTION_ID ) return parents;
					const parent = plainTree.find(el=>el.ID === section.IBLOCK_SECTION_ID);
					if ( !parent ) return parents;

					return parentsOf(parent, parents);
				};

				return parentsOf(this.sectionOf(item)).reverse().join(" – ");
			},
			sectionOf(item) {
				const plainTree = this.plainTree();
				return plainTree.find(el=>el.ID === item.IBLOCK_SECTION_ID);
			},
			sectionIndexOf(item) {
				const plainTree = this.plainTree();
				return plainTree.findIndex(el=>el.ID === item.IBLOCK_SECTION_ID);
			},
			plainTree() {
				const plainChildrenOf = (items) => {
					if (!items) return [];
					//console.log("STEP: ", items);
					return items.reduce((all, item) => {
						if (!item.children) return [...all, item];
						return [...all, item, ...plainChildrenOf(item.children)];
					}, []);
				};
				return plainChildrenOf(this.tree);
			},
			initItems() {
				this.items = this.orderItems?.map(el => {
					const p = this.products?.find(p => el.XML_ID === p.XML_ID);
					// clone
					el = {...el, ...p};

					// amounts can be anything - normalize
					const sections = this.plainSectionsOf(el);
					Vue.set(el, "fullSectionName", sections);
					Vue.set(el, "amount", (+el?.amount) || 0);
					Vue.set(el, "confirmedAmount", (+el?.confirmedAmount) || 0);

					return el;
				}).sort((a,b)=>{
					const sectionIndexA = this.sectionIndexOf(a);
					const sectionIndexB = this.sectionIndexOf(b);
					if ( sectionIndexA < sectionIndexB ) return -1;
					if ( sectionIndexA > sectionIndexB ) return 1;
					if ( Number(a.SORT) < Number(b.SORT) ) return -1;
					if ( Number(a.SORT) > Number(b.SORT) ) return 1;
					return 0;
				});
			},
        }
    }
</script>

<style lang="scss">
	.order-content {
		&__header {
			&.root {
				font-weight: bold;
			}
		}

		.v-data-table-header {
			th:last-child {
				padding-right: 24px;
			}
		}

		tr.over {
			background: $altered;
		}

		tr.altered {
			background: $altered;
		}

		tr.empty {
			background: $empty;
		}

		&__code, &__section {
			font-size: 10px;
			color: $gray-dark;
		}
	}
</style>